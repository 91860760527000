$fa-font-path: "../font";
$html-font-size: 16;
$white: #fff;
$black: #000;
$green: #30B575;


@font-face {
  font-family: 'Caslon';
  src: url('../font/CaslonMedium.woff2') format('woff2'),
      url('../font/CaslonMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Caslon';
  src: url('../font/CaslonBook.woff2') format('woff2'),
      url('../font/CaslonBook.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Caslon';
  src: url('../font/CaslonBold.woff2') format('woff2'),
      url('../font/CaslonBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../font/Inter-Bold.woff2') format('woff2'),
      url('../font/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../font/Inter-Medium.woff2') format('woff2'),
      url('../font/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../font/Inter-Light.woff2') format('woff2'),
      url('../font/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}




$primary-font: Caslon, serif;
$secondary-font: Inter, sans-serif;
