#instagram-feed {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  z-index: 1;

  >div {
    width: 33%;
    padding: 1%;

    @media screen and (max-width: 600px) {
      flex-basis: 50%;
    }

    a {
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      overflow: hidden;
      padding-bottom: 100%;
      position: relative;
      width: 100%;
      height: 0;

      image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
      }

      span {
        align-items: center;
        background-color: rgba(0, 0, 0, .7);
        color: $white;
        display: flex;
        font-weight: 300;
        justify-content: center;
        line-height: 1.3;
        padding: rem(15);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transform: translateX(-100%);
        transition: transform .3s;
        display: none;
      }

      &:active,
      &:focus,
      &:hover {
        span {
          transform: translateX(0);
        }
      }
    }
  }
}