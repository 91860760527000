@import "variables";
@import "framework/framework";
@import "./feed";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	width: 100%;
	overflow-x: hidden;
	font-family: $secondary-font;
	background: url(../img/bg/bg-texture.png), linear-gradient(180deg, #BE2D3C 0%, #2B070B 100%);
	background-color: transparent, transparent;
	background-position: fixed, center;
	background-size: cover, cover;
	background-repeat: no-repeat;
	background-attachment: fixed, scroll;
	position: relative;
}

a {
	color: inherit;

	@include hover-focus {
		color: $white;
	}
}

h1,
h2,
h3 {
	font-family: $primary-font;
	text-transform: uppercase;
}

h2 {
	font-size: rems(60);
	font-weight: normal;
}

ul {
	list-style: none;
	display: flex;
	margin: 0;
	padding: 0;
}

.btn {
	background-color: $green;
	color: $black;
	border-radius: rems(25);
	font-size: rems(19);
	font-family: $primary-font;
	font-weight: 300;
	padding: rems(11) rems(59);
	text-transform: uppercase;
}

.social {
	display: flex;
	align-items: center;
	padding-left: rems(60);

	li {
		a {
			display: block;
			max-width: rems(20);
			transition: all .3s ease-in-out;

			@include hover-focus {
				transform: scale(1.2);
			}
		}
	}
}

header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	nav {
		background-color: transparent;
		display: flex;
		justify-content: space-between;

		h1 {
			img {
				max-width: rems(415);

				@media screen and (max-width: 1100px) {
					max-width: rems(250);
				}
			}
		}

		.nav-wrapper {
			display: flex;

			@media screen and (max-width: 1100px) {
				flex-direction: column;
				align-items: flex-end;
				margin-top: rems(100);
			}

			.nav-links {
				gap: rems(47);

				@media screen and (max-width: 1100px) {
					flex-direction: column;
					align-items: flex-end;
					gap: rems(20);
				}

				li {
					display: flex;
					align-items: center;

					a {
						font-size: rems(15);
						font-weight: 300;
						text-transform: uppercase;
						letter-spacing: rems(4);

						@include hover-focus {
							color: $green;
						}

						@media screen and (max-width: 1100px) {
							font-size: rems(30);
						}
					}
				}
			}

			.nav-social {
				gap: rems(7.5);

				@media screen and (max-width: 1100px) {
					margin-top: rems(24);
				}

				li {}
			}

			@media screen and (max-width: 1100px) {
				&:not(.active) {
					opacity: 0;
					pointer-events: none;
				}
			}
		}
	}

	.mobile-menu-wrapper {
		position: absolute;
		top: 0;
		right: 0;

		.hamburger {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 35px;
			height: 40px;
			pointer-events: all;
			cursor: pointer;
			position: relative;
			z-index: 30;

			@media screen and (min-width: 1101px) {
				display: none;
			}

			@media screen and (max-width: 700px) {
				margin: rems(20);
			}
		}

		.line {
			width: 100%;
			height: 2px;
			background-color: $white;
			margin: 4.5px;
			transition: transform 0.3s ease;
			transform-origin: center;


			&.active {
				transform: rotate(45deg) !important;
				position: absolute;
				top: 30%;

				&.active:nth-child(2) {
					display: none;
				}

				&.active:nth-child(3) {
					transform: rotate(-45deg) !important;
				}
			}
		}
	}
}

main {

	section#hero {
		height: 100vh;
		background-image: url(../img/hero.jpg);
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
	}

	section#listen {
		margin-bottom: rems(214);

		@media screen and (max-width: 1100px) {
			margin-bottom: rems(120);
		}

		.container {
			h2 {}

			.wrapper {
				display: flex;
				gap: rems(20);

				@media screen and (max-width: 1100px) {
					display: block;
				}

				.cover {
					flex: 1;

					@media screen and (max-width: 1100px) {
						padding-bottom: rems(30);
					}

					img {
						margin: 0 auto;
					}
				}

				.info {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;

					p {
						font-weight: 300;
						font-size: rems(15);
						text-transform: uppercase;
						letter-spacing: rems(5);
					}

					.title {
						font-family: $primary-font;
						font-size: rems(33);
						letter-spacing: rems(1);
						margin: rems(13) 0 rems(23);
					}

					.btn-wrapper {
						display: flex;

						a.btn {
							margin: rems(37) rems(10) 0;
							padding: rems(11) rems(40);

							i {
								margin-right: rems(8);
							}
						}
					}
				}
			}
		}
	}

	section#watch {
		margin-bottom: rems(169);

		.container {

			h2 {}

			.embed-container {
				margin-bottom: rems(47);

				iframe {}
			}

			p {
				text-align: center;
				text-transform: uppercase;
				font-weight: 300;
				letter-spacing: rems(5);
				font-size: rems(15);
			}

			.mobile {
				@media screen and  (min-width: 1100px) {
					display: none;
				}
			}

			.desktop {
				@media screen and  (max-width: 1100px) {
					display: none;
				}
			}
		}
	}

	section#tour {
		margin-bottom: rems(170);

		.container {
			.wrapper {
				margin-bottom: rems(68);

				h2 {
					margin: 0;

					img {}
				}

				p {
					padding-left: rems(8);
					letter-spacing: rems(4);
					text-transform: uppercase;
					font-size: rems(17);
				}
			}

			.seated-events-table {
				border: none;

				@media screen and (max-width: 1100px) {
					font-size: rems(20);
				}

				// row = item
				.seated-event-row {
					border: none;

					.seated-event-description-cells {
						.seated-event-date-cell {
							font-family: $primary-font;

							@media screen and (max-width: 1100px) {
								margin-bottom: rems(8);
							}
						}

						.seated-event-venue-cell {
							.seated-event-venue-name {
								@media screen and (max-width: 1100px) {
									margin-bottom: rems(8);
								}
							}

							.seated-event-venue-location {
								@media screen and (max-width: 1100px) {
									margin-bottom: rems(8);
								}
							}
						}

						.seated-event-details-cell {}
					}

					.seated-event-link-cells {
						.seated-event-link-cell {

								@media screen and (max-width: 1100px) {
									display: flex;
									justify-content: center;
								}

							.seated-event-link {
								background-color: $green;
								color: $black;
								border-radius: rems(25);
								font-size: rems(14);
								font-family: $primary-font;
								font-weight: 300;
								padding: rems(11) rems(59);
								text-transform: uppercase;
								padding: rems(11) 0;
								text-align: center;
								width: rems(140);
								font-family: $primary-font;
								border: none;
							}
						}
					}
				}
			}

			// #tour-dates {
			// 	.event-group {
			// 		display: flex;
			// 		gap: rems(16);
			// 		margin: rems(24) 0;
			// 		font-weight: 300;

			// 		@media screen and (max-width: 1100px) {
			// 			text-align: center;
			// 			display: block;
			// 			margin: rems(36) 0;
			// 		}


			// 		.date-venue-wrapper {
			// 			flex: 1;
			// 			font-family: $primary-font;
			// 			letter-spacing: rems(2);

			// 			.event-date {
			// 				@media screen and (max-width: 1100px) {
			// 					padding-bottom: rems(12);
			// 				}
			// 			}

			// 			.event-venue {
			// 				@media screen and (max-width: 1100px) {
			// 					padding-bottom: rems(12);
			// 				}
			// 			}
			// 		}

			// 		.event-location {
			// 			flex: 1;
			// 			letter-spacing: rems(2);

			// 			@media screen and (max-width: 1100px) {
			// 				padding-bottom: rems(12);
			// 			}
			// 		}

			// 		.event-links {
			// 			flex: 1;
			// 			gap: rems(12);
			// 			display: flex;
			// 			justify-content: center;

			// 			@media screen and (max-width: 1100px) {
			// 				flex-direction: column;
			// 				max-width: rems(210);
			// 				margin: 0 auto;
			// 			}

			// 			a.btn {
			// 				padding: rems(11) rems(63);
			// 				font-family: $primary-font;
			// 			}
			// 		}
			// 	}
			// }
		}
	}

	section#gallery {
		margin-bottom: rems(142);

		.container {
			h2 {

				@media screen and (max-width: 700px) {
					font-size: rems(40);
				}

				@media screen and (max-width: 350px) {
					font-size: rems(30);
				}
			}

			#instagram-feed {

			}
		}
	}
}

// .popup {
// 	background-color: $black;
// 	padding: rems(50) rems(80);
// 	max-width: rems(400);
// 	color: $white;
// 	margin: 0 auto;
// 	position: relative;
// 	font-size: rems(22);
// 	text-align: center;
// 	background: linear-gradient(180deg, #BE2D3C 0%, #2B070B 100%);
// 	background-color: transparent;
// 	background-position: center;
// 	background-size: cover;

// 	.mfp-close {
// 		color: $white;
// 	}

// 	form {
// 		display: flex;
// 		flex-direction: column;
// 		align-items: center;
// 		justify-content: center;
// 		gap: rems(30);

// 		p {
// 			font-size: rems(22);
// 			font-weight: bold;
// 			text-align: center;
// 			font-family: $primary-font;

// 			span {}
// 		}

// 		.inputs-wrap {
// 			display: flex;
// 			align-items: center;
// 			justify-content: center;
// 			flex-direction: column;
// 		}

// 		label {
// 			display: block;
// 		}

// 		input {
// 			border: none;
// 			margin-bottom: rems(35);
// 		}

// 		select {
// 			width: 100%;
// 			margin-bottom: rems(35);
// 		}

// 		option {
// 			width: 100%;
// 		}
// 	}
// }

footer {
	padding-bottom: rems(176);

	.container {
		.text {
			text-transform: uppercase;
			font-family: $primary-font;
			font-size: rems(33);
			font-weight: normal;
			letter-spacing: rems(1);
			margin-bottom: rems(63);
			text-align: center;
		}

		.wrapper {
			display: flex;

			@media screen and (max-width: 1100px) {
				display: block;
				text-align: center;
			}

			.copyright {
				font-weight: 300;
				font-size: rems(12);
				max-width: rems(651);
			}

			.footer-social {
				gap: rems(13.5);
				margin: 0 auto;
				padding: 0;

				@media screen and (max-width: 1100px) {
					justify-content: center;
				}

				li {
					a {
						display: block;
						max-width: rems(38);
					}
				}
			}
		}
	}
}